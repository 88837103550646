import Vue from "vue";
import VueRouter from "vue-router";
import Login from '../views/Login';
import store from "../store/index"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/store",
    name: "Store",
    component: () =>
      import("../views/Store.vue")
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () =>
      import("../views/Checkout.vue")
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import("../views/Account.vue")
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import("../views/Orders.vue")
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: "/order/:orderId/view",
    name: "View",
    component: () => import("../views/Order.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !store.state.account.firstName) next({path: '/'})
  next();
})

export default router;
