import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.filter('currency', amount => {
  if (amount) return store.state.account.currencySymbol +
    parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  else return (store.state.account.currencySymbol || '') + '0.00';
});

Vue.filter('prettyDate', dateText => {
  const date = new Date(dateText);
  return date.getDate() + '/' + (+date.getMonth() + 1) + '/' + date.getFullYear();
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
